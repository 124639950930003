.game {
	background-color: black;
	border: 1px solid blue;
	display: block;
	margin: 40px auto;
}

.botPre {
	margin-top: 40px;
	position: relative;
}
